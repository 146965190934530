<!-- *************************** 登录 ****************************** -->
<template>
  <div class="login_container">
    <div class="container">
      <div class="outer">
        <div class="content">
          <div>
            <h1 class="title">登录</h1>
            <div class="log">
              <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
                <el-form-item prop="areaCode">
                  <VueCountryIntl v-model="ruleForm.areaCode"></VueCountryIntl>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                  <el-input
                    type="text"
                    placeholder="请输入手机号"
                    v-model="ruleForm.phone"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                  <el-input
                    type="password"
                    placeholder="请输入密码"
                    v-model="ruleForm.password"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <router-link to="/login/get_back_pwd" class="forget_pwd">忘记密码？</router-link>
                <el-form-item>
                  <el-button type="primary" @click="submitForm('ruleForm')">登录</el-button>
                </el-form-item>
              </el-form>
              <p class="not_new_user">
                新用户？
                <router-link to="/register">建立新账号</router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { loginApi } from "api/Login";
export default {
  data() {
    //校验手机号
    const phone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      }else{
        callback();
      }
    };
    //校验密码
    const password = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/.test(value)) {
        callback("密码至少包含数字和英文,长度6-20");
      } else {
        callback();
      }
    };
    //校验国家
    const areaCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        phone: "", //手机号
        areaCode: "61", //国家
        password: "" //密码
      },
      rules: {
        phone: [{ validator: phone, trigger: ["blur", "change"] }],
        areaCode: [{ validator: areaCode, trigger: ["blur", "change"] }],
        password: [{ validator: password, trigger: ["blur", "change"] }]
      }
    };
  },
  methods: {
    //登录提交
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let params = {};
          const { phone, areaCode, password } = this.ruleForm;
          params = { phone: "+" + areaCode + " " + phone, password };
          loginApi(params).then(res => {
            if (res.code == 0) {
              this.$store.dispatch({
                type: "changeLoginStatus",
                login: true
              });
              sessionStorage.setItem("token", res.data);
              this.$router.push({ path: "/admin_center" });
            }
          });
        }
      });
    }
  },
};
</script>
<style scoped lang='scss'>
.login_container {
  overflow: hidden;
  background-size: cover;
  .outer {
    padding: 80px;
  }
  .content {
    color: #797979;
    width: 400px;
    background: #fff;
    border-radius: 5px;
    padding: 30px 20px 50px 20px;
    box-shadow: 0 0 10px #517cfc;
    margin: 0 auto;
    ::v-deep .el-tabs__nav-wrap::after {
      background-color: #fff;
    }
    ::v-deep .vue-country-intl {
      .country-intl-label {
        width: auto !important;
        padding: 0 0 0 10px !important;
        box-sizing: border-box !important;
      }
      .country-intl-input-wrap {
        width: 100% !important;
        box-sizing: border-box !important;
      }
      .country-intl-input {
        width: 100% !important;
        box-sizing: border-box;
      }
      .vue-country-list-wrap {
        box-sizing: border-box;
      }
      .vue-country-list {
        width: 100% !important;
        li {
          padding: 0 10px !important;
          box-sizing: border-box;
          text-align: left;
        }
      }
    }
    .title {
      padding-bottom: 25px;
      font-size: 30px;
      color: #517cfc;
    }
    .log {
      text-align: right;
      a {
        color: #517cfc;
        text-decoration: underline;
      }
    }
    .forget_pwd {
      margin-bottom: 20px;
      display: inline-block;
      cursor: pointer;
      text-decoration: none;
    }
    ::v-deep .el-tabs__header {
      margin: 0 0 20px;
    }
    .el-input_inner {
      border: 1px solid #cdcdcd;
    }
    .el-button--primary {
      width: 100%;
      background-color: #517cfc;
      border-color: #517cfc;
    }
    .send_code {
      width: 118px;
      background: #517cfc;
      color: #fff;
    }
  }
}
</style>